import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import "./ImageList.css";
import $ from "jquery";

class FooterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      subscriber: props.subscriber,
    };
    this.getCurrentProviderOperator = props.getCurrentProviderOperator;
    this.getCurrentPage = props.getCurrentPage;
    this.getCurrentLang = props.getCurrentLang;
    this.getLanguages = props.getLanguages;
    this.__ = props.__;
  }
  renderfooter() {
    let providerop = this.getCurrentProviderOperator();
    if (providerop == "DIGITAL_HTML_BH") {
      return (
        <>
          <a href="https://dev.digitalizehub.com/web/bosnia/faq/playhub/">{this.__("TERMS")} -</a>
          <a href="https://dev.digitalizehub.com/web/bosnia/kontakt/playhub/">{this.__("CONTACT")} -</a>
          <a href="https://dev.digitalizehub.com/web/bosnia/politika/playhub/"> {this.__("PRIVACY&POLICY")}</a>
        </>
      );
    } else if(providerop == "DIGITAL_HTML_PL"){
      return (<>
        <a href="https://prod.digitalizehub.com/web/poland/tnc/playhub/">{this.__("TERMS")} -</a>
        <a href="https://prod.digitalizehub.com/web/poland/kontakt/playhub/">{this.__("CONTACT")} -</a>
        <a href="https://prod.digitalizehub.com/web/poland/pravni/playhub/"> {this.__("FAQ")}</a>
      </>);
    }
    else {return ;}
  }
  render() {
    return (
      <div
        className="footer-bottom border-color section"
        data-lang={this.getCurrentLang()}
        style={this.getCurrentLang() == "AR" ? { direction: "rtl" } : {}}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright text-center">
              {this.renderfooter()}
                <p>
                {this.__("COPYRIGHT")}
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterMenu;
